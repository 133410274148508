<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pmCollapse.vue
Description: This file contains UI components that show collapable PondMothers show in collapsable ponds in Feeding PondMother page - PondMother Level
-->
<template>
  <el-row>
    <el-collapse
      accordion
      @change="handleExpandChange"
      :value="getExpandedKeys"
      class="pm-schedule-collapse"
    >
      <template v-for="pmId in pondPmIds">
        <el-collapse-item
          v-if="pmId !== ''"
          :key="pmId"
          :name="pmId"
          v-loading="loading === pmId"
          :disabled="disablePMCollapseItem(pmId)"
        >
          <template slot="title">
            <el-row
              type="flex"
              justify="space-between"
              class="collapse-header__row"
            >
              <el-col :span="4" class="collapse-header__row-item">
                <svg
                  class="list-item__color-swatch"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="3" :fill="getPmMode(pmId).color">
                    <title>{{ getPmMode(pmId).translation }}</title>
                  </circle>
                </svg>
                <p class="row-item__value truncate">
                    {{ getPmTitle(pmId) }}
                  <!-- getCollapseItemData(pmId).title -->
                </p>
                <el-tag v-if="isMasterPmAvailable(pmId)" class="row-item__value" type="info" size="mini">{{isMasterPm(pmId)}}</el-tag>
              </el-col>
              <el-col v-if="isCurrentDay" :span="5" class="collapse-header__row-item">
                <p class="row-item__label truncate">{{ $t("Comn_shrimptalk") }}</p>
                :
                <p class="row-item__value truncate">{{ getStData(pmId) }}</p>
              </el-col>
              <el-col :span="isCurrentDay ? 4 : 6" class="collapse-header__row-item">
                <p class="row-item__label truncate">{{ $t("PM_tf") }}</p>
                :
                <p
                  class="row-item__value truncate"
                  v-upm__units.digitPrecision.formatEmpty="
                    unitsParamsForFeed(pmId)
                  "
                ></p>
              </el-col>
              <el-col :span="isCurrentDay ? 4 : 7" class="collapse-header__row-item">
                <p class="row-item__label truncate">
                  {{ $t("Comn_df") }}
                </p>
                :
                <p
                  class="row-item__value truncate"
                  v-upm__units.digitPrecision.formatEmpty="
                    unitsParamsForDispensedFeed(pmId)
                  "
                ></p>
              </el-col>
              <el-col
                :span="3"
                class="collapse-header__row-item status-item-container"
                v-show="getDateQueryType === 'TODAY'"
              >
                <el-tooltip
                  popperClass="pm-schedule-collapse-el-tooltip__popper"
                  effect="light"
                  :content="getDeviceLastCommunicationColorString(pmId).title"
                  placement="top"
                >
                  <i
                    class="material-icons-round"
                    :style="
                      `color:${
                        getDeviceLastCommunicationColorString(pmId).color
                      }`
                    "
                    >signal_cellular_alt</i
                  >
                </el-tooltip>
                <el-tooltip
                  popperClass="pm-schedule-collapse-el-tooltip__popper"
                  effect="light"
                  :content="getSchedStatus(pmId).translation"
                  placement="top"
                >
                  <i
                    class="material-icons-round"
                    :style="`color:${getSchedStatus(pmId).color};`"
                    >sync</i
                  >
                </el-tooltip>
                <!-- <er-button class="er-btn-refresh" type="text">
                  <i class="material-icons-round">refresh</i>
                </er-button> -->
              </el-col>
              <el-col :span="4" class="collapse-header__row-item">
                <p class="row-item__value truncate">
                  {{ getPmFeedingLevel(pmId) }}
                  <!-- getCollapseItemData(pmId).title -->
                </p>
              </el-col>
            </el-row>
          </template>
          <component
            :is="getRunningMode(pmId) ? pmModeToComponent['HYBRID'] : pmModeToComponent[getPmMode(pmId).mode]"
            :pm="getCollapseItemData(pmId)"
            :pondId="pondId"
            :mode="getRunningMode(pmId) ? 'HYBRID' : getPmMode(pmId).mode"
            :selectedDay="daySelectedAtPm(pmId)"
          ></component>
        </el-collapse-item>
      </template>
    </el-collapse>
  </el-row>
</template>

<script>
import pmAutomaticModeCollapseItem from "./pmAutomaticModeCollapseItem";
import pmScheduleModeCollapseItem from "./pmScheduleModeCollapseItem";
import pmBasicModeCollapseItem from "./pmBasicModeCollapseItem";
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { DATE_OBJ_DATE_FORMAT_WITH_TIME } from "@/utils/commonUtils";
import pmSchedulesMixin from "@/mixins/pmSchedulesMixin";
import PondMother from "@/model/pondmother";
import { TS_PM_MODES } from "@/constants/schedule";
export default {
  props: ["pond"],
  mixins: [errorHandlerMixin, pmSchedulesMixin],
  components: {
    pmScheduleModeCollapseItem,
    pmAutomaticModeCollapseItem,
    pmBasicModeCollapseItem
  },
  data: function() {
    return {
      pondSelected: this.pond,
      expandedPmKeys: [],
      pmModeToComponent: {
        FARMER: "pmScheduleModeCollapseItem",
        MANUAL: "pmBasicModeCollapseItem",
        SHRIMP_TALK: "pmAutomaticModeCollapseItem",
        HYBRID: "pmScheduleModeCollapseItem"
      },
      loading: "",
      TS_PM_MODES: TS_PM_MODES
    };
  },
  computed: {
    ...mapGetters("pondMotherSchedules", {
      getPmCollapseTableData: "getPmCollapseTableData",
      getSelectedDay: "getSelectedDay",
      getPmTSTableData: "getPmTSTableData",
      getArrOfDaysFromDateRange: "getArrOfDaysFromDateRange",
      getCommunicationStatusForSelectedPondDevices:
        "getCommunicationStatusForSelectedPondDevices",
      getDateQueryType: "getDateQueryType",
      getPmSchedLastUpdtStatus: "getPmSchedLastUpdtStatus",
      getPondToPmsMapInSchedOnSelectedPondAndDate:
        "getPondToPmsMapInSchedOnSelectedPondAndDate",
      getExpandedPmKeysPondId: "getExpandedPmKeysPondId",
      isFutureDay: "isFutureDay",
      isPastDay: "isPastDay",
      isCurrentDay: "isCurrentDay"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    disablePMCollapseItem() {
      return (pmId) => {
        return (
          this.isFutureDay &&
            ["SHRIMP_TALK", "MANUAL"].includes(this.getPmMode(pmId).mode),
          !new Set(this.getSelectedPmIds).has(pmId)
        );
      };
    },
    isMasterPmAvailable() {
      return (pmId) => { return (this.isCurrentDay && this.isMasterPm(pmId)) } // && (this.getPmMode(pmId).mode === 'SHRIMP_TALK' || this.getPmMode(pmId).mode === 'HYBRID')
    },
    pondId: function() {
      return this.pond._id;
    },
    selectedDay: function() {
      return this.getSelectedDay;
    },
    getExpandedKeys: function() {
      return (this.getExpandedPmKeysPondId[this.pondId] || []).filter(
        (pmId) => !this.disablePMCollapseItem(pmId)
      );
    },
    getDeviceLastCommunicationColorString() {
      return (pmId) => {
        const objMap = {
          ON_LINE: {
            color: "#33A02C",
            title: this.$t("online")
          },
          OFF_LINE: { color: "#E31A1C", title: this.$t("offline") }
        };
        const statusCode = (
          this.getCommunicationStatusForSelectedPondDevices[pmId] || {
            status_code: "OFF_LINE"
          }
        ).status_code;
        return objMap[statusCode];
      };
    },
    daySelectedAtPm: function() {
      return (pmId) => {
        return this.getSelectedDay;
      };
    },
    getCollapseItemData: function() {
      return (pmId) => {
        const daySelected = this.daySelectedAtPm(pmId) || this.daySelected;
        return (
          (this.getPmCollapseTableData[daySelected + "_" + this.pondId] || {})[
            pmId
          ] || {}
        );
      };
    },
    getPmTitle() {
      return (pmId) => {
        return this.upm__getValue(
          this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
        );
      };
    },
    isMasterPm() {
      return (pmId) => {
        if (this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId].is_master === true) {
          return "P";
        } else if (this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId].is_master === false) {
          return "S";
        }
      }
    },
    getStData() {
      return (pmId) => {
        const stData = {
          alias_name: this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId].shrimp_talk_title,
          code: this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId].shrimp_talk_display_code
        }
        return stData[this.getPreferredUnits.device_name] ? stData[this.getPreferredUnits.device_name] : '--'
      }
    },
    getPmFeedingLevel() {
      // const translations = {
      //   NORMAL: this.$t("PM_normal"),
      //   ECONOMICAL: this.$t("PM_economical"),
      //   AGGRESSIVE: this.$t("PM_Aggressive"),
      //   HYBRID: this.$t("PM_hybrid"),
      //   NONE: ""
      // };
      const translations = {
        0: this.$t("PM_normal"),
        1: this.$t("PM_economical"),
        2: this.$t("PM_Aggressive"),
        3: this.$t("Comn_super_aggressive"),
        4: this.$t("PM_hybrid"),
        5: ""
      };
      return (pmId) => {
        return this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
          .managed_by === "SHRIMP_TALK"
          ? translations[
              this.$lodash.get(
                this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId],
                "feeding_level"
              )
            ]
          : "";
      };
    },
    tableDataObj() {
      return (
        this.getPmCollapseTableData[this.daySelected + "_" + this.pondId] || {}
      );
    },
    tableData() {
      return Object.values(this.tableDataObj).sort(
        this.$commonUtils.naturalSort("title")
      );
    },
    pondPmIds() {
      return Object.keys(this.getPondToPmsMapInSchedOnSelectedPondAndDate);
    },
    getPmMode() {
      const translations = {
        SHRIMP_TALK: this.$t("PM_automatic"),
        FARMER: this.$t("Comn_schedule"),
        MANUAL: this.$t("PM_sch_mode4"),
        HYBRID: this.$t("hybrid"),
        NO_MODE: this.$t("no_mode")
      };
      return (pmId) => {
        const mode = TS_PM_MODES[this.getPondToPmsMapInSchedOnSelectedPondAndDate[pmId]
          .managed_by];
        const color = PondMother.generateColorForMode(mode).color;
        return { mode, color, translation: translations[mode] };
      };
    },
    getSchedStatus: function() {
      return (pmId) => {
        const status = this.getPmSchedLastUpdtStatus(
          this.getSelectedDay,
          this.pondId,
          pmId
        );
        const formatTime = this.dhm__dateUtilsLib.formatTZWithLocale(
          this.dhm__dateUtilsLib.parseISO(status.updated_iso_string),
          DATE_OBJ_DATE_FORMAT_WITH_TIME,
          this.getUserTimeZoneString,
          this.$i18n.locale
        );
        const objStatusMap = {
          NO_SCHEDULES: (time) => ({
            translation: this.$t("PM_no_sch_today"),
            color: "#979797"
          }),
          SCHEDULES_NOT_UPDATED: (time) => ({
            translation: this.$t("PM_sch_not_updated"),
            color: "#E31A1C"
          }),
          SCHEDULES_UPDATED: (time) => ({
            translation: this.$t("schedule_updtd_in_device", { time }),
            color: "#33A02C"
          })
        };
        return objStatusMap[status.status_code](formatTime);
      };
    }
  },
  mounted() {
    this.upm__setDataKeys("code", "title");
  },
  methods: {
    ...mapActions("pondMotherSchedules", {
      changeInExpandPmKeys: "changeInExpandPmKeys"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    runningModeData(pmId) {
      return this.$lodash.cloneDeep(Object.values(this.getPmTSTableData[`${this.selectedDay}_${this.pondId}_${pmId}`] || {}));
    },
    getRunningMode(pmId) {
      return this.isPastDay && this.runningModeData(pmId)[0]?.running_mode && this.runningModeData(pmId)[0].running_mode === 'HYBRID';
    },
    unitsParamsForFeed(pmId) {
      const feed = this.getCollapseItemData(pmId).feed || 0;
      const feedUnits = {
        value: feed,
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__backendUnits.feed_units,
        modifiersParams: {
          digitPrecision: [2],
          formatEmpty: ["-"]
        }
      };
      return feedUnits;
    },
    unitsParamsForDispensedFeed(pmId) {
      const feed = this.getCollapseItemData(pmId).dispensed_feed || 0;
      const feedUnits = {
        value: feed,
        previousunits: this.upm__backendUnits.feed_units,
        units: this.upm__backendUnits.feed_units,
        modifiersParams: {
          digitPrecision: [2],
          formatEmpty: ["-"]
        }
      };
      return feedUnits;
    },
    async handleExpandChange(pmId) {
      if (pmId === "") {
        return;
      }
      if (!Array.isArray(pmId)) {
        this.changeInExpandPmKeys({ pondId: this.pondId, pmId: [pmId] });
      }
      this.mixPanelEventGenerator({
        eventName: "Feeding - PondMother - PondMother Collapse"
      });
    }
  }
};
</script>

<style lang="scss">
.pm-schedule-collapse {
  cursor: pointer;
  .er-btn-refresh {
    visibility: hidden;
    pointer-events: none;
  }
}
.pm-schedule-collapse-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}
.pm-schedule-collapse-el-tooltip__popper[x-placement^="top"] .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
</style>
